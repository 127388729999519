<template>
  <div>
    <PageHeader>
      <template slot="right">
        <div>
          <b-button
            v-if="$allowPermission('agent:manage.line-notification')"
            variant="primary"
            @click="toggleModal(null)"
          >
            <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add') }}
          </b-button>
        </div>
      </template>
    </PageHeader>
    <b-overlay :show="isFetching">
      <b-card>
        <h6>
          {{ `${$t('fields.result')} (${total})` }}
        </h6>
        <b-row align-v="center" class="mb-3">
          <b-col cols="12" md="3">
            <LimitSelect v-model="selectedLimit" />
          </b-col>
          <b-col cols="12" md="9">
            <b-row>
              <b-col cols="5">
                <b-input-group>
                  <b-form-input
                    v-model="search"
                    :placeholder="`${$t('fields.search')}...`"
                    class="mx-1"
                    type="search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col v-if="isOwner">
                <MasterSelectInput
                  :value="selectedMaster"
                  hide-label
                  @update="onMasterIdChange"
                />
              </b-col>
              <b-col>
                <AgentsSelectInput
                  :master-id="selectedMaster"
                  :value="selectedAgent"
                  hide-label
                  @update="onAgentIdChange"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Table -->
        <b-table :fields="fields" :items="filteredList" responsive show-empty>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
           <template #cell(type)="data">
            <b-badge 
              v-if="+data.value === 0"
              class="badge-soft-success"
            >
                ฝาก
            </b-badge>
            <b-badge 
              v-else-if="+data.value === 1"
              variant="danger"
            >
                แจ้งถอน
            </b-badge>
            <b-badge 
              v-else-if="+data.value === 2"
              class="badge-soft-danger"
            >
                OTP ถอน
            </b-badge>
            <b-badge 
              v-else-if="+data.value === 4"
              class="badge-soft-info"
            >
                แก้ไขข้อมูลยูสเซอร์
            </b-badge>
             <b-badge
                 v-else-if="+data.value === 5"
                 class="badge-info"
             >
               โยกเงินอัตโนมัติ
             </b-badge>
            <b-badge 
              v-else
              variant="dark"
            >
              {{ data.value }}
            </b-badge>
          </template>
          <template #cell(agent)="data">
            {{ data.value.name || '-' }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(id)="data">
            <b-button-group
              v-if="$allowPermission('agent:manage.line-notification')"
              size="sm"
            >
              <button
                class="btn btn-info btn-sm"
                type="button"
                @click="onEdit(data.value)"
              >
                {{ $t('buttons.edit') }}
              </button>
               <button
                class="btn btn-danger btn-sm"
                type="button"
                @click="onDelete(data.value)"
              >
                {{ $t('buttons.remove') }}
              </button>
            </b-button-group>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{
              $t('messages.nothing_here')
            }}</p>
          </template>
          <template #cell(note)="data">
            <span v-if="data.value.length === 0">-</span>
            <b-badge v-else>{{ data.value }}</b-badge>
          </template>
        </b-table>
        <!-- pagination -->
        <PaginationInput
          :per-page="limit"
          :total="total"
          @update="(val) => (currentPage = val)"
        />
      </b-card>
    </b-overlay>
    <LineNotificationFormModal ref="lineNotificationForm" :selected-id="selectedLineId" no-btn/>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'จัดการแจ้งเตือนไลน์',
  },
  components: {
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
    LineNotificationFormModal: () =>
      import('@components/forms/line-notification-form-modal.vue'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      onSearchTimeout: null,
      fields: [
        '#',
        {
          key: 'agent',
          label: 'เอเย่นต์',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'type',
          label: 'ประเภท',
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'name',
          label: 'ชื่อ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'token',
          label: 'โทเคน',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'createdAt',
          label: this.$t('fields.createdAt'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],
      search: '',
      selectedMaster: '',
      selectedAgent: '',
      selectedNote: '',
      selectedLineId: '',
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.line.isFetchingLineNotifications,
      lineNotifications: (state) => state.line.lineNotifications,
    }),
    ...mapGetters(['isOwner']),
    filteredList() {
      return this.lineNotifications.items || []
    },
    pagination() {
      return this.lineNotifications.meta
    },
    limit() {
      return this.pagination?.itemsPerPage
    },
    total() {
      return this.pagination?.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchLineNotifications', 'deleteLineNotification']),
    fetchData() {
      this.fetchLineNotifications({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    toggleModal() {
      this.$refs.lineNotificationForm.toggleModal()
    },
    onDelete(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteLineNotification(id)
          }
        })
    },
    onEdit(id){
      this.selectedLineId = id
      this.toggleModal()
    },
  },
}
</script>
